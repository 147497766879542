<template lang="pug">

v-card.app-payments
  v-card-title
    span
      v-btn(icon color="black" @click="close" small).mr-2
        v-icon mdi-arrow-left
      span.app-payments__title Compose price

  v-divider
  v-card-text

    v-row
      v-col(cols="4")
        v-progress-circular(indeterminate size=34 data-test="payment-loading" v-if="itemsLoading" color="warning")

        div(v-if="!itemsLoading")
          .app-payments__create.app-create-payments
            h3.app-payments__title Choose payment
            v-spacer

          choose-payment(
            :payments="payments"
            :recomendedPayment="_recomendedPayment"
            :formErrors="formErrors"
            @change="select"
            @click:custom="showCustomLabel"
          )
            v-radio(:value="newPayment.value" @change="showCustomLabel" label="custom").mt-2
          payments-editor(:item="editorData" :formErrors="formErrors" v-if="showEditor" ref="editor")
          //- v-radio-group(hide-details).mt-0
          //-   v-radio( data-test="payment-item"
          //-     v-for="item in payments" :value="item.value"
          //-     @change="select(item)"
          //-     :label="`${item.label} (${item.value}€)`")
          //-   v-radio(:value="newPayment.value" @change="showCustomLabel" label="custom").mt-2



      v-col(cols="8")
        h3.email-templates__subtitle Log
        log(:list="log" :loading="logsLoading")
          template(v-slot:actions="props")
            slot(name="log-actions" :paymentLog="props.payment" :isPaid="props.isPaid")
  v-card-actions
  div
    v-divider
    .d-flex.pl-5.pr-5.pt-3.pb-3.flex-wrap
      v-spacer
      v-btn(
          @click="close"
          color="primary"
          data-test='payment-submit'
          outlined
        ).btn-common.mr-3 close

      v-btn(
        @click="submit"
        :loading="processing"
        color="primary"
        data-test='payment-submit'
        :disabled="!selector.activeElement"
      ) send

</template>

<script>
import {PAYMENT_LABEL, PaymentSelector, PaymentCustom} from "@/models/paymentModel"
import Selector from '../../../../../../util/selector'

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    itemsLoading: Boolean,
    logs: Array,
    logsLoading: Boolean,
    loadData: Function,
    formErrors: Object,
    processing: Boolean,
    editorData: Object,
    log: Array,
    recomendedPayment: String
  },

  data: () => ({
    payments: [],
    showing: false,
    selected: null,
    selector: new Selector(),
    newPayment: new PaymentSelector(
      new PaymentCustom( {label: PAYMENT_LABEL.CUSTOM })
    ),
    showEditor: false
  }),

  computed: {
    _recomendedPayment() {
      if(!this.recomendedPayment) return {}
      let payment = this.payments.find(item => item.label === this.recomendedPayment)
      if(payment) return payment
      return {}
    },
  },


  mounted() {
    this.initData()
  },

  methods: {
    initData() {
      this.payments = this.items.map(item => new PaymentSelector(item))
    },

    select(payment) {
      this.showEditor = false
      this.selector.set(payment)
    },

    showCustomLabel() {
      this.select(this.newPayment)
      this.$emit('click:custom')
      this.showEditor = true
    },

    async submit() {
      let data = this.getSelectedData()

      if(data.label !== this._recomendedPayment.label ) return this.$emit('submit:notRecomendedPayment', data)
      this.$emit('submit', data)
    },

    getSelectedData() {
      if(this.selector.activeElement.label === PAYMENT_LABEL.CUSTOM) {
        let data = this.$refs.editor.getData()
        return new PaymentCustom(data)
      }
      return this.selector.activeElement
    },

    save() {
      this.$emit('update')
    },

    remove() {
      this.$emit('remove')
    },

    close() {
      this.$emit('close')
    }
  },

  watch: {
    items() {
      this.initData()
    }
  },

  components: {
    // customTemp: () => import('./CandidateCustomETemp.vue'),
    // appLabel: () => import('@/components/global/OutlinedLabel.vue'),
    log: () => import('./CandidatesPaymentLog.vue'),
    paymentsEditor: () => import('./CandidatesPaymentsEditor.vue'),
    choosePayment: () => import('./CandidatesChoosePayment.vue')
  },
}
</script>

<style lang="scss" scoped>
.app-payments {
  &__title {
    font-size: 14px;
    color: $title-second;
  }
}

.templates-create {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  &__btn {
    width: 33.3333333333%;
    padding-left: 4px;

    .v-icon {
      color: inherit;
    }
  }
}

.email-templates {
  &__create {
    margin-top: 24px;
  }
  &__subtitle {
    font-size: 14px;
    color: $title-second;
  }
}

.email-templates-title {
  color: $title-second;
}

.open-btn {
  color: rgba(0, 0, 0, 0.54);
}

</style>
